<template>
    <div class="auth-page-wrapper pt-5">
        <!-- auth page bg -->
        <div class="auth-one-bg-position auth-one-bg" id="auth-particles">
            <div class="bg-overlay"></div>

            <div class="shape">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 1440 120">
                    <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                </svg>
            </div>
            <canvas class="particles-js-canvas-el" width="1518" height="380" style="width: 100%; height: 100%"></canvas>
        </div>

        <!-- auth page content -->
        <div class="auth-page-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center mt-sm-5 mb-4 text-white-50">
                            <div>
                                <a href="index.html" class="d-inline-block auth-logo">
                                    <img src="/assets/images/logo-light.png" alt="" width="200" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row -->

                <div class="row justify-content-center">
                    <div class="col-md-9">
                        <div class="card mt-0">
                            <div class="card-body p-4">
                                <div class="text-center mt-2">
                                    <h5 class="text-primary">Validasi Sertifikat Saham</h5>
                                </div>
                                <div v-if="!load">
                                    <div v-if="share">

                                        <div class="mt-2">
                                            <div class="mb-3">
                                                <label class="form-label">Status</label>
                                                <input type="text" v-bind:value="'valid'" class="form-control"
                                                    placeholder="Masukkan email" readonly />
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <div class="mb-3">
                                                <label class="form-label">Tanggal Penerbitan</label>
                                                <input type="text" v-bind:value="toDate(share['created_at'])"
                                                    class="form-control" placeholder="Masukkan email" readonly />
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <div class="mb-3">
                                                <label class="form-label">Pemilik</label>
                                                <input type="text" v-bind:value="share['owner']['name']"
                                                    class="form-control" placeholder="Masukkan email" readonly />
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <div class="mb-3">
                                                <label class="form-label">Alamat</label>
                                                <input type="text" v-bind:value="share['owner']['investor']['address']"
                                                    class="form-control" placeholder="Masukkan email" readonly />
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <div class="mb-3">
                                                <label class="form-label">Nomor Telepon</label>
                                                <input type="text" v-bind:value="share['owner']['investor']['phone_number']"
                                                    class="form-control" placeholder="Masukkan email" readonly />
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <div class="mb-3">
                                                <label class="form-label">Jumlah Slot</label>
                                                <input type="text" v-bind:value="share['slot']" class="form-control"
                                                    placeholder="Masukkan email" readonly />
                                            </div>
                                        </div>

                                        <div class="mt-2">
                                            <div class="mb-3">
                                                <label class="form-label">Slot</label>
                                                <input type="text" v-bind:value="share['share_period']['name']"
                                                    class="form-control" placeholder="Masukkan email" readonly />
                                            </div>
                                        </div>

                                    </div>
                                    <div v-else>
                                        <div class="text-center py-5">
                                            <div class="mb-4">
                                                <lord-icon src="https://cdn.lordicon.com/tyounuzx.json" trigger="loop"
                                                    colors="primary:#0ab39c,secondary:#405189"
                                                    style="width:120px;height:120px">
                                                </lord-icon>
                                            </div>
                                            <h5 class="">Sertifikat tidak terdaftar dalam database kami!</h5>
                                            <p class="text-danger">Penyalahgunaan sertifikat dapat dikenakan pasal
                                                pidana.</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <FormShimmer :count="9" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <footer class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-center">
                            <p class="mb-0 text-muted">2022 - {{ currentYear }} © {{ appName }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import axios from 'axios';
import { endpoint } from '../host';
import httpService from '../services/http-service';
import FormShimmer from '../components/shimmer/FormShimmer.vue';
import globalService from '../services/global-service';
import moment from 'moment';

export default {
    components: { FormShimmer },
    created() {
        var scripts = [
            "assets/libs/particles.js/particles.js",
            "assets/js/pages/particles.app.js",
        ];
        scripts.forEach((script) => {
            let tag = document.createElement("script");
            tag.setAttribute("src", script);
            document.head.appendChild(tag);
        });
        let params = this.$route.params;
        console.log(params);
        this.share_number = params.share_number;
        this.getShareValidation();
    },
    data() {
        return {
            load: false,
            share: null,
            share_number: null,
        };
    },
    methods: {
        async getShareValidation() {
            this.load = true;
            let res = await httpService.get(endpoint.share + `/validation/${this.share_number}`);
            if (res.data.data) {
                this.share = res.data.data;
            }
            this.load = false;
        },

        toDate(a) {
            return globalService.dateFormat(a);
        }
    },
    computed: {
        currentYear() {
            return moment().year();
        },
        appName() {
            return process.env.VUE_APP_TITLE;
        },
    },
};
</script>

<style>
</style>